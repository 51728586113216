import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  ChatAttachmentDto,
  ChatMessageDto,
  ChatMessageTypeEnum,
} from "api/notification";
import { store } from "store";
import { loadChatAttachment } from "store/slices/chat";
import { getDateWithTimezone } from "store/slices/chat/utils";
import theme from "theme";
import {
  StatusIconView,
  StatusIconSent,
  StatusIconError,
} from "widgets/ChatWidget/assets";
import { FilesMessages } from "../FilesMessages";
import { OperatorIcon } from "../OperatorIcon";
import { TextMessage } from "../TextMessage";

type MessageItemProps = {
  message: ChatMessageDto & { isError?: boolean };
};

const MessageItem = ({ message }: MessageItemProps) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { text, createdAt, user, read, attachments, isError, type } = message;
  const [files, setFiles] = useState<ChatAttachmentDto[]>([]);

  const handleFileDownload = useCallback(
    (attachment: ChatAttachmentDto) => () => {
      dispatch(loadChatAttachment(attachment.id || ""))
        .unwrap()
        .then((res) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = `data:image/png;base64,${res}`;
          downloadLink.download = attachment.fileName || "File";
          downloadLink.click();
        });
    },
    [dispatch]
  );

  const hasRead = read ? <StatusIconView /> : <StatusIconSent />;
  const statusIcon = isError ? <StatusIconError /> : hasRead;

  useEffect(() => {
    attachments?.forEach((attachment: ChatAttachmentDto & { url?: string }) => {
      setFiles((prevState) => [...prevState, attachment]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    (!text && attachments?.length === 0) ||
    type !== ChatMessageTypeEnum.Regular
  )
    return null;

  return (
    <Box
      justifyContent={!user ? "flex-end" : "flex-start"}
      display="flex"
      gap={8}
      marginBottom={12}
    >
      {user && <OperatorIcon />}
      <Box
        display={!user ? "flex" : "block"}
        alignItems={!user ? "flex-end" : "initial"}
      >
        {user && (
          <Typography
            mb={4}
            typography="text_3"
            color={theme.palette.blue.b400}
          >
            {user}
          </Typography>
        )}
        <Box
          sx={{
            backgroundColor: user
              ? "var(--brand-color-hover)"
              : "var(--main-color-bg-widgets)",
            borderRadius: "20px",
          }}
          display="flex"
          gap={8}
          maxWidth={282}
          px={16}
          py={16}
          mr={4}
        >
          <Box
            gap={text && files.length > 0 ? 8 : 0}
            display="flex"
            flexDirection="column"
          >
            {text && <TextMessage text={text} user={user} />}
            <FilesMessages
              files={files}
              onClick={handleFileDownload}
              user={user}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            height="fit-content"
            marginTop="auto"
            gap={4}
          >
            <Typography
              display="flex"
              alignSelf="flex-end"
              typography="text_0"
              fontWeight={600}
              color={theme.primary.main.gray["300"]}
            >
              {getDateWithTimezone(createdAt)}
            </Typography>
            {!user && (
              <Box display="flex" alignItems="flex-end">
                {statusIcon}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageItem;
