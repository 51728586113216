import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  checkIsBiometryKeyExist,
  createPin,
} from "store/slices/auth/asyncThunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelector } from "store";
import { Button, Typography } from "@mui/material";
import { Loader } from "components";
import { CodeInput } from "components/CodeInput";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsPWA } from "hooks/useIsPWA";
import { authActions } from "store/slices/auth";
import { BackButtonArrow } from "components/BackButtonArrow";
import styles from "./style.module.css";
import { useResetStore } from "hooks/useResetStore";
import { hideErrorMessage } from "store/slices/alerts";
import PinInput from "react-pin-input";

export const PIN_LENGTH = 4;

export type PinCodeType = {
  value1: string;
  value2: string;
};

export const PinCode: FC = () => {
  const resetStore = useResetStore();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = useMemo(() => {
    return {
      value1: "",
      value2: "",
    };
  }, []);
  const [pinCode, setPinCode] = useState<PinCodeType>(initialValues);
  const { isLoading, isInitialPin } = useSelector(authSelector);
  const [errorStatus, setErrorStatus] = useState(false);
  const { value1, value2 } = pinCode;
  let ele = useRef<PinInput | null>(null);
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();
  const [value, setValue] = useState("");
  const isPinComplete =
    value1.length === PIN_LENGTH && value2.length === PIN_LENGTH;
  const isAuth = () => {
    localStorage.setItem("isAuthed", "true");
  };
  const tmpLogin = localStorage.getItem("tmpLogin") || "";

  const handleChangeWeb = (code?: string) => {
    const currentStep = isInitialPin ? 1 : 2;
    setErrorStatus(false);
    if (code?.length === PIN_LENGTH) {
      setPinCode({
        ...pinCode,
        [`value${currentStep}`]: code
          ? `${pinCode[`value${currentStep}`]}${code}`
          : pinCode[`value${currentStep}`].slice(0, -1),
      });
      //@ts-ignore
      ele?.current?.clear();
      setValue("");
    }
  };

  const logout = () => {
    if (isInitialPin) {
      dispatch(authActions.setElseDevice(true));
      navigate("/", { replace: true });
      return;
    }
    if (!isInitialPin) {
      dispatch(authActions.setIsInitialPin(true));
      return;
    }
    const deviceIdItem = localStorage.getItem("deviceId");
    const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    localStorage.clear();
    if (deviceIdItem !== null) {
      localStorage.setItem("deviceId", deviceIdItem);
    }
    if (PWAdeviceIdItem !== null) {
      localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    }
    sessionStorage.clear();
    dispatch(authActions.setIsPinForgotten(true));
    dispatch(hideErrorMessage());
    navigate("/sign-in", { replace: true });
    resetStore();
  };

  const setRef = (ref: PinInput | null) => {
    ele.current = ref;
  };

  useEffect(() => {
    if ((isMobile || isPWA) && value1.length === PIN_LENGTH) {
      dispatch(authActions.setIsInitialPin(false));
    }
    if (isPinComplete) {
      if (value1 === value2) {
        dispatch(createPin({ pinCode: value1, repeatPin: value2 }));
        dispatch(authActions.setIsPinAllowed(true));
        localStorage.setItem("login", tmpLogin);
        localStorage.removeItem("useBiometry");
        if (isPWA) {
          dispatch(checkIsBiometryKeyExist())
            //@ts-ignore
            .unwrap()
            .then((res) => {
              isAuth();
              navigate("/biometry-registration", { replace: true });
            })
            .catch((err) => {
              isAuth();
              if (err.response.data.code === "WRONG_DEVICE") {
                dispatch(
                  authActions.setAnotherDeviceError({
                    title: err.response.data.title,
                    description: err.response.data.subtitle,
                  })
                );
                navigate("/login-error");
              } else {
                navigate("/", { replace: true });
              }
            });
        } else {
          isAuth();
          navigate("/", { replace: true });
        }
      } else {
        setErrorStatus(true);
        setPinCode(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  useEffect(() => {
    return () => {
      dispatch(authActions.setIsInitialPin(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isInitialPin) {
      //@ts-ignore
      ele?.current?.clear();
      setPinCode(initialValues);
    }
  }, [isInitialPin, initialValues]);

  return (
    <div
      style={{
        paddingTop: isMobile ? "0px" : "150px",
        paddingBottom: isMobile ? "0px" : "150px",
      }}
    >
      <div style={{ backgroundColor: "white" }}>
        <div className={styles.container}>
          <div className={styles.backBtn}>
            <BackButtonArrow onClick={logout} />
          </div>
          <div className={styles.titleBlock}>
            <p className={styles.title}>
              {isInitialPin ? "Создайте код для входа" : "Повторите код"}
            </p>
          </div>
          <>
            {isLoading ? (
              <Loader size={68} />
            ) : (
              <div className={styles.content}>
                {errorStatus && (
                  <Typography
                    variant="text_5"
                    color="var(--error-color-icon)"
                    mb={14}
                  >
                    Ошибка: коды не совпадают
                  </Typography>
                )}
                <CodeInput
                  setRef={setRef}
                  length={4}
                  secret
                  secretDelay={100}
                  setCode={setValue}
                  error={errorStatus}
                  clearError={() => setErrorStatus(false)}
                />
                <Button
                  //@ts-ignore
                  disabled={value.length !== 4}
                  onClick={() => {
                    if (isInitialPin) {
                      dispatch(authActions.setIsInitialPin(false));
                    }
                    handleChangeWeb(value);
                  }}
                  variant="primary"
                  size="lg"
                  fullWidth
                  className={styles.btn}
                  style={{ marginTop: "40px" }}
                >
                  Продолжить
                </Button>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
