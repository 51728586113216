import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "theme";

type TextMessageProps = {
  user?: string;
  text: string;
};

const TextMessage = ({ text, user }: TextMessageProps) => {
  return (
    <Box width="100%" maxWidth={218} sx={{ wordBreak: "break-all" }}>
      <Typography
        typography="text_5"
        sx={{ wordBreak: "break-word", hyphens: "auto" }}
        color={theme.primary.main.gray["800"]}
        dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br/>") }}
      />
    </Box>
  );
};

export default TextMessage;
