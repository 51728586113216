import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/locale/ru_RU";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "./styles.css";
import { useEffect, useRef } from "react";

dayjs.locale("ru");

interface Props {
  onChange: (value: string | string[]) => void;
  placeholder?: string;
}

export const DateInput = ({ onChange, placeholder }: Props) => {
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (_, value: string | string[]) => {
    onChange(value);
  };

  useEffect(() => {
    if (datePickerRef.current) {
      const inputElement = datePickerRef.current.querySelector("input");
      if (inputElement) {
        inputElement.setAttribute("readOnly", "true");
      }
    }
  }, []);

  return (
    <div ref={datePickerRef}>
      <ConfigProvider locale={locale}>
        <DatePicker
          onChange={handleChange}
          placeholder={placeholder || ""}
          suffixIcon={<IconCalendar />}
          showNow={false}
          format="DD.MM.YYYY"
          style={{
            width: "100%",
            borderRadius: "16px",
            height: "68px",
            padding: "0px 16px",
            backgroundColor: "var(--main-color-bg-widgets)",
            color: "var(--main-color-text-title)",
            border: "none",
            cursor: "text",
          }}
        />
      </ConfigProvider>
    </div>
  );
};
