import styled from "@emotion/styled/macro";

export const Container = styled.div`
  width: 93%;
  border-radius: 15px;
  padding: 10px 15px 15px 15px;
  background: var(--main-color-bg-widgets);
  position: absolute;
  bottom: 50px;
  z-index: 1;

  @media (max-width: 430px) {
    width: -webkit-fill-available;
  }
`;

export const Title = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--main-color-text-title);
  text-align: center;
`;

export const Description = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #777e8a;
  text-align: center;
`;

export const CloseWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
`;

export const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Star = styled.img`
  width: 38px;
  height: 36px;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--brand-color-primary);
  color: white;
  border-radius: 12px;
  width: 100%;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

export const Icon = styled.img`
  width: 48px;
  height: 46px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const RepeatButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: #ffffff;
  color: var(--brand-color-primary);
  border-radius: 12px;
  width: 100%;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px;
`;

export const IconRepeat = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
